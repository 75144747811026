@import "../assets/styles/reset";

.ant-upload {
    &-picture-card-wrapper {
        border: 1px solid #CCCCCC;
        padding: 20px;
    }

    &-list-picture-card-container {
        width: 146px;
        height: 146px;
    }

    &-list-picture-card .ant-upload-list-item {
        width: 146px;
        height: 146px;
    }

    &-select-picture-card.ant-upload {
        width: 146px;
        height: 146px;
    }
}

.product-single {
    padding: 0 20px;
    position: relative;

    .ant-form {
        color: #333;

        &-item {
            margin-bottom: 15px;
        }

        &-item-label {
            min-width: 110px;
        }

        .ant {
            &-input, &-select, &-input-number {
                width: 30%;
                min-width: 150px;
            }

            &-picker {
                width: 40%;
                min-width: 200px;
            }

            &-textarea {
                width: 50%;
                resize: none;
                height: 120px;
            }
        }
    }

    .btn-primary {
        padding: 5px 12px;
    }

    &-label {
        line-height: 32px;
    }

    .nodot {
        margin-bottom: -5px;

        .ant-form-item-label > label::after {
            display: none;
        }

        .ant-checkbox-disabled + span {
            color: inherit;
        }
    }

    &-detailform {
        .ant-form {
            &-item {
                margin-bottom: 5px;
            }

            &-item-label { /*width: 100px;*/
            }
        }

        .product-single-form-textbox {
            margin-bottom: 10px;
        }
    }

    &-form {
        font-size: 14px;

        &-itembox {
            @extend .flex-start;
            flex-wrap: wrap;

            .ant-form-item {
                width: 40%;
                margin-right: 5%;

                .ant-input, .ant-select, .ant-input-number {
                    width: 100%;
                }
            }
        }

        &-textbox {
            padding: 10px;
            border: 1px solid #dcdcdc;
            height: 150px;
            overflow-y: auto;
            line-height: inherit;
        }

        &-infobox {
            position: absolute;
            top: 0;
            right: 20px;
            line-height: 30px;
        }

        &-btnbox {
            position: fixed;
            bottom: 50px;
            right: 20px;
            z-index: 5;

            .ant-form-item-control-input-content {
            }

            .btn {
                padding: 6px 12px;
                font-size: 14px;
                margin: 5px 0 !important;
                display: block;
                width: 124px;
            }
        }
        /*&-btnbox { padding: 20px 0;
            .ant-form-item-control-input-content {
                @extend .flex-center;
            }
            .btn { padding: 10px 50px; font-size: 16px; margin: 0 20px; }
        }*/
    }

    &-modal {
        max-width: 650px;

        .product-single-form-item {
            padding: 0;
            margin-bottom: 20px;
        }

        .ant-form-item {
            margin-bottom: 10px;

            &-label {
                width: 85px;
            }

            &-control {
                overflow: hidden;
            }
        }

        .ant-picker {
            width: 100%;
        }
    }

    .bf-container {
        border: 1px solid #CCCCCC;
        background-color: #FFF;

        .bf-controlbar {
            border-bottom: 1px solid #CCC;
            background-color: #F0F0EE;

            .separator-line {
                margin: 10px;
            }
        }

        .bf-content {
        }
    }

    .navbox {
        margin-bottom: 20px;

        a {
            padding: 6px 20px;
            border-radius: 3px;
            cursor: pointer;
            text-decoration: none;
        }

        .tagbtn {
            color: #3983e5;

            &.active {
                background: #3983e5;
                color: #fff;
            }
        }

        .togglebtn {
            margin-left: 10px;
            border: 1px solid #dcdcdc;
            color: inherit;
        }
    }

    .addGroup {
        position: absolute;
        right: 20px;
        top: -55px;
    }

    .save-btnbox {
        @extend .flex-center;
        padding: 20px 0;

        .btn {
            padding: 10px 50px;
            font-size: 16px;
            margin: 0 20px;
        }
    }

    .datesbox {
        background: #fcfcfc;
        border: 1px solid #ebebeb;
        margin: 0 -1px;
        padding: 20px;
        margin-bottom: 20px;

        .addbtn {
            color: #428bca;
            border: 1px solid;
            background: #fff;
            padding: 6px 20px;
            border-radius: 3px;
        }
    }

    .edit-inpbox {
        padding: 0 20px;

        &-week {
            @extend .flex-start;
        }

        .item {
            @extend .flex-start;

            .ant-form-item {
                width: 50%;

                .ant-input, .ant-select, .ant-input-number {
                    width: 80%;
                }
            }
        }
    }
}

.product-giftsingle {
    .ant-form {
        color: #333;

        &-item {
            margin-bottom: 15px;
        }

        &-item-label {
            min-width: 110px;
        }

        .ant {
            &-input, &-select, &-input-number {
                width: 30%;
                min-width: 150px;
            }

            &-picker {
                width: 40%;
                min-width: 200px;
            }

            &-textarea {
                width: 50%;
                resize: none;
                height: 120px;
            }
        }
    }

    &-block {
        font-size: 14px;

        & + & {
            border-top: 1px solid #dcdcdc;
            padding-top: 20px;
            margin-top: 10px;
        }

        h4 {
            font-weight: bold;
            font-size: 16px;
        }

        .textbox {
            flex: 1;
            display: flex;
            overflow: hidden;

            &-inner {
                flex: 1;
                overflow: hidden;
            }

            &-list {
                @include text-overflow;
            }

            &-content {
                max-height: 64px;
                overflow: hidden;

                &--active {
                    max-height: none;
                }
            }

            .textbox {
                margin-left: 0;
            }
        }

        .more {
            color: #1890ff;
            cursor: pointer;
        }
    }

    &-item {
        padding-left: 32px;
        display: flex;
        line-height: 32px;
        @include flex-wrap(nowrap);

        &-lable {
            flex-shrink: 0;
            min-width: 100px;
        }

        &-content {
            flex: 1;
            word-break: break-all;
            overflow: hidden;
        }
    }

    .prices-textbox {
        display: flex;

        .textbox {
            margin-left: 40px;
        }
    }
}

.product-cruise {
    .ant-form {
        color: #333;

        &-item {
            margin-bottom: 15px;
        }

        &-item-label {
            min-width: 110px;
        }

        .ant {
            &-input, &-select, &-input-number {
                width: 30%;
                min-width: 150px;
            }

            &-picker {
                width: 40%;
                min-width: 200px;
            }

            &-textarea {
                width: 50%;
                resize: none;
                height: 120px;
            }
        }
    }

    &-block {
        font-size: 14px;
        padding: 20px;

        & + & {
            border-top: 1px solid #dcdcdc;
            padding-top: 20px;
            margin-top: 10px;
        }

        h4 {
            font-weight: bold;
            font-size: 18px;
            margin: 0;
        }
    }

    &-header {
        @extend .flex-between;
        margin-bottom: 20px;
    }

    &-extra {
        @extend .flex-end;

        .item + .item {
            margin-left: 20px;
        }
    }

    .ant-row {
        .ant-col-24 {
            .ant-form-item {
                width: 100%;
                padding-right: 20px;
            }
        }

        .ant-form-item {
            width: 100%;
            padding-right: 20px;

            .ant-input, .ant-select, .ant-input-number, .ant-picker {
                width: 100%;
                min-width: auto;
            }
        }
    }

    .itembox-col25 {
        .ant-form-item {
            @extend .flex-start;
            flex-wrap: nowrap;

            &-label {
                min-width: 70px;
            }

            &-control {
                width: auto;
            }
        }
    }

    &-table {
        width: 100%;
        text-align: center;
        table-layout: fixed;

        th {
            background: #d4e0f2;
            padding: 12px 10px;
        }

        td {
            padding: 12px 10px;
            border-bottom: 1px solid #ececec;
        }

        .td01 {
            width: 200px;
        }

        .td02 {
            width: 220px;
        }

        .td-oparte {
            width: 95px;
        }

        .img {
            display: block;
            width: 100%;
            cursor: pointer;
        }

        a {
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }

        .noPic {
            padding: 40px 0;
        }

        .invalid {
            color: #999;

            a {
                color: inherit;
            }
        }
    }

    .joinbox {
        @extend .flex-start;
        width: 50%;

        .ant-form-item {
            flex: 1;
            margin-right: 20px;

            .ant-input, .ant-select, .ant-input-number {
                width: 100%;
                min-width: auto;
            }
        }

        .btn {
            margin-bottom: 15px;
            flex-shrink: 0;
        }
    }

    .blockbox {
        margin-bottom: 20px;
        @extend .flex-start;

        .list {
            @extend .flex-center;
            @include flex-direction(column);
            background: #fffdf5;
            border: 1px solid #ffe3a7;
            width: 146px;
            height: 84px;
            cursor: pointer;

            &-active {
                background: #ffe3a7;
                cursor: auto;
            }
        }

        .num {
            background-color: #74cc1e;
            color: #fff;
            border-radius: 80px;
            padding: 2px 20px;
        }
    }
}

.cruise-infobox {
    .ant-form-item {
        width: 100%;
        padding-right: 20px;

        &.col50 {
            width: 50%;
        }

        .ant-input, .ant-select, .ant-input-number, .ant-picker {
            width: 100%;
            min-width: auto;
        }
    }
}

.selectedbox {
    display: flex;
    margin-top: 5px;

    .text {
        flex-shrink: 0;
        color: #1890ff;
        line-height: 32px;
    }

    .inner {
        flex: 1;
        overflow: hidden;

        .list {
            @include flexbox;
            @include justify-content(space-between);
            align-items: center;
        }
    }

    .list-name {
        @include text-overflow;
    }
}

.coupon-table {
    colgroup {
        display: none;
    }

    .td-operate {
        width: 110px;
    }
}

.groupsbox {
    .list {
        border: 1px solid #dcdcdc;
        border-radius: 3px;
        margin-bottom: 20px;
    }

    .headbox {
        padding: 15px;
        background: #deeefc;
        @extend .flex-between;

        .name {
            font-weight: bold;
            font-size: 16px;
        }

        .state {
            font-size: 16px;
            color: #ff8a26;
            font-weight: bold;

            &.red {
                color: red;
            }
        }

        .toggle {
            background: #fff;
            border: 1px solid #ccc;
            padding: 2px 10px;
            border-radius: 3px;
            cursor: pointer;
            display: block;
        }
    }

    .innerbox {
        padding: 20px;
        position: relative;

        .ant-dropdown-link {
            background-color: #3276b1;
            border: 1px solid #285e8e;
            color: #fff;
            padding: 5px 12px;
            border-radius: 4px;
        }

        .button {
            line-height: 28px;
            border: 1px solid #e8e8e8;
            background: #fafafa;
            padding: 0 30px;
            margin-left: 10px;
            border-radius: 3px;
            cursor: pointer;
        }

        .invalid {
            position: absolute;
            top: -10px;
            right: -10px;
            width: 80px;
        }
    }

    .relatedbox {
        padding: 10px 0;
        border-top: 1px solid #dcdcdc;
        border-bottom: 1px solid #dcdcdc;
        margin: 10px 0;
    }

    .catenabox {
        border: 1px solid #428bca;
        padding: 20px;
        margin-top: 20px;
        position: relative;
        overflow: hidden;

        .namebox {
            @extend .flex-between;
            margin-bottom: 15px;

            .name {
                font-weight: bold;
                font-size: 15px;
            }
        }
    }

    .listbox {
        border: 1px solid #dcdcdc;
        padding: 20px 20px 10px;
        margin-top: 20px;
        position: relative;
        overflow: hidden;

        .name {
            font-weight: bold;
        }
    }

    .unit-infobox {
        @include flex-wrap(wrap);
        @extend .flex-start;
        line-height: 40px;

        .tit {
            min-width: 70px;
            text-align: right;
            padding: 10px 0;
        }

        a {
            cursor: pointer;
            margin-right: 30px;
        }

        .subtit {
            padding: 10px 0;
        }
    }
}

.group-inpbox {
    border-top: 1px solid #dcdcdc;
    padding-top: 20px;

    .group-hdbox {
        position: relative;

        .add-salebtn {
            position: absolute;
            bottom: 0;
            right: 0;
        }

        .failurebtn {
            position: absolute;
            top: 0;
            right: 0;
        }
    }

    .failurebtn {
        border: 1px solid #bbb;
        background: #ecf6ff;
        font-size: 14px;
        padding: 5px 15px;
    }

    .listbox {
        background: #deeefc;
        padding: 20px;
        margin-top: 20px;
        position: relative;

        .del {
            position: absolute;
            top: 0;
            right: 20px;
            color: #428bca;
            font-size: 30px;
            cursor: pointer;
            text-decoration: none;
        }

        .failurebtn {
            position: absolute;
            top: 0;
            right: 0;
        }

        & > .failurebtn {
            position: absolute;
            top: 20px;
            right: 20px;
        }

        .ant-form-item {
            padding-right: 20px;
            margin-bottom: 15px;
        }

        .failurebtn {
            top: 20px;
            right: 20px;
        }

        .item-inpbox {
            .item-tit {
                width: 100px;
            }

            .item-inp {
                width: 145px;
            }
        }

        .invalid {
            position: absolute;
            top: -10px;
            right: -10px;
            width: 80px;
        }

        .holiday {
            flex: 1;

            .btn {
                flex-shrink: 0;
                margin-right: 10px;
                cursor: pointer;
            }
        }

        .ant-form-item {
            margin-bottom: 15px;

            .ant-input, .ant-select, .ant-input-number {
                width: 100%;
                min-width: auto;
            }

            .ant-textarea {
                height: 120px;
            }
        }
    }

    .steamerbox {
        background: #fff;
        padding: 20px 20px 0;
        border: 1px solid #dcdcdc;
        border-radius: 3px;
        margin-bottom: 20px;
        position: relative;
        overflow: hidden;
    }

    .salebox {
        @extend .flex-start;
        @include flex-wrap(wrap);

        .ant-form-item {
            width: 33.333333%;
        }

        &-col50 {
            .ant-form-item {
                width: 50%;
            }
        }
    }

    .unitbox {
        @include flex-wrap(wrap);

        .item-inpbox {
            width: 25%;
        }
    }
}

.stock-calenderbox {
    .ant-modal-body {
        max-height: none;
        padding: 5px 15px 15px;
    }

    .ant-picker-calendar {
        &-mode-switch {
            display: none;
        }

        &-year-select {
            width: 100px;
        }

        &-month-select {
            width: 80px;
        }

        &-full .ant-picker-panel .ant-picker-calendar-date-content {
            height: 40px;
            text-align: center;
            line-height: 20px;
            margin-top: -5px;
        }
    }

    .stock {
        color: #ff930e;
    }

    .calendar {
        &-date {
            margin: 0px 4px;
            padding: 4px 8px 0px;
            border: 2px solid transparent;
            border-top-color: #dcdcdc;
            height: 72px;
            cursor: default;

            &-active {
                background: #deeefc;
            }

            &-multi {
                cursor: pointer;
            }

            &-selected {
                border-color: #428bca;
            }

            &-content {
                text-align: center;
                line-height: 20px;
            }
        }
    }

    .btnbox {
        @extend .flex-start;
        margin-top: 15px;

        .btn {
            padding: 4px 15px;
            margin-right: 15px;
        }
    }
}

.stock-inpbox {
    .title {
        text-align: center;
    }

    .btn {
        padding: 4px 6px;
        border-radius: 4px;
        cursor: pointer;
        flex-shrink: 0;
        border: 1px solid transparent;
    }

    .btn-primary {
        color: #fff;
        background-color: #428bca;
        border-color: #357ebd;
    }

    .btn-danger {
        color: #fff;
        background-color: #d9534f;
        border-color: #d43f3a;
    }

    .btn-default {
    }

    .inpbox {
        margin-top: 20px;
        @extend .flex-start;

        .btn {
            margin-left: 10px;
        }
    }

    .btnbox {
        margin-top: 20px;
        @extend .flex-center;

        .btn {
            padding: 4px 15px;
            margin: 0 10px;
        }
    }
}

.previewImage {
    width: 600px !important;

    .ant-modal-body {
        max-height: 600px;
        padding: 20px;
    }

    .preview {
        display: block;
        width: 100%;

        &-video {
            max-height: 560px;
        }
    }
}

.districtSelectbox {
    width: 600px !important;

    .table {
        .td01 {
            text-align: right;
        }
    }

    .ant-select {
        width: 300px;

        &-selector {
            @include flex-direction(column);
            @include align-items(flex-start);
        }
    }
}

.supplierbox {
    border: 1px solid #428bca;
    border-radius: 3px;
    @extend .flex-start;

    .item {
        padding: 5px 10px;
        cursor: pointer;
    }

    .active {
        background: #428bca;
        color: #fff;
        cursor: default;
    }
}

.service-normbox {
    @extend .flex-center;
    text-align: center;

    &-item {
        margin-right: 10px;
    }

    .label {
        border: 1px solid #ebebeb;
        width: 120px;
        display: block;
        line-height: 50px;
        font-weight: bold;
        cursor: pointer;
    }

    .active {
        border-color: #d43f3a;
        color: #d43f3a;
    }
}

.sale-groupModal {
    .ant-row {
        @include flex-wrap(nowrap);

        .ant-col {
            flex: 1
        }
    }

    .text {
        height: 110px;
    }

    .line {
        width: 1px;
        background: #1894d4;
        margin: 0 20px;
        flex-shrink: 0;
    }

    .btn {
        background: #5cb85c;
        color: #fff;
    }
}

/*---------------------*/
.prolistbox {
    width: 90%;
    margin-bottom: 10px;

    .list {
        margin-bottom: 20px;
    }

    .list-before {
        @extend .flex-start;
        padding: 15px;
        border-radius: 10px;
        @include linear-gradient(#ceeff0,#e3e4e4);
        position: relative;

        .inner {
            flex: 1;
        }

        .del-btn {
            position: absolute;
            top: 15px;
            right: 15px;
            background: #fff;
            cursor: pointer;
            color: #f44848;
            width: 80px;
            padding: 4px;
        }

        .title {
            padding-right: 100px;
            font-size: 20px;
        }

        .imgbox {
            width: 170px;
            @include flex-shrink(0);
            margin-right: 15px;

            img {
                display: block;
                width: 100%;
            }
        }

        .item + .item {
            margin-left: 20px;
        }

        .price {
            color: #ff9900;
            font-size: 20px;
        }
    }

    .list-after {
        padding: 20px 20px 0;
        border: 1px solid #ddd;
        box-shadow: 3px 3px 3px #eee;
        position: relative;
        overflow: hidden;

        .invalid {
            position: absolute;
            top: -10px;
            right: -10px;
            width: 60px;
            z-index: 2;
        }

        .stock-inp {
            display: inline-block;
            margin-right: 30px;
        }

        .calenderbox-btnbox {
            margin-bottom: 15px;
        }

        .pricebox {
            padding: 15px 0;
            border-top: 1px solid #dcdcdc;
            margin-top: 15px;

            .list {
                @extend .flex-between;
            }

            .type {
                font-weight: bold;
                padding-right: 10px;
                flex: 1;
            }

            .price {
                width: 20%;
            }

            .setdata {
                @extend .flex-start;
                width: 310px;
            }
        }
    }

    .list-btnbox {
        min-height: 52px;
        padding: 10px 0;
        @extend .flex-end;

        &-addGroup {
            padding: 10px 0;
            margin-bottom: -52px;
        }
    }
}

.proposebox {
    border: 1px solid #ccc;
    margin: 20px 40px 20px 0;
    padding: 10px 20px;
    border-radius: 5px;

    .titlebox {
        @extend .flex-between;
        margin-bottom: 15px;

        .title {
            font-size: 18px;
            line-height: 48px;
            display: inline-block;
            width: 550px;

            span {
                font-weight: bold;
                color: #06c;
            }
        }
    }

    .ant-form {
        &-item {
            margin-bottom: 15px;
        }

        &-item-label {
            min-width: 112px;
        }
    }

    &-item {
        margin-bottom: 15px;
    }
}

.district-Select {
    &.flex-start {
        @include align-items(flex-start);
    }

    &-tit {
        margin-top: 8px;
    }

    &-del {
        font-size: 20px;
        margin-left: 3px;
    }

    .toggle {
        line-height: 30px;
        color: #1890ff;
        cursor: pointer;
    }
}
/*产品小类*/
.product-category {
    height: calc(100vh - 114px) !important;

    &-header {
        padding: 5px 0;
        padding-left: 10px;
        font-weight: bold;
    }

    &-sider {
        width: 200px;
        height: calc(100vh - 124px) !important;
        padding-left: 10px;

        .ant-layout-sider-children {
            @extend .flex-start;
            flex-direction: column;
            align-items: stretch;

            .ant-tree {
                flex: 1;
                overflow: auto;
            }
        }
    }

    &-content {
        margin-left: 220px;
        min-height: auto;
        padding-top: 0;
        @extend .flex-start;
        flex-direction: column;
        align-items: stretch;

        .scrollElement {
            flex: 1;
            overflow: auto;
        }
    }

    &-table {
        colgroup, .ant-table-measure-row {
            display: none;
        }

        table {
            text-align: center;

            th {
                padding: 0 !important;
                text-align: center !important;
            }

            td {
                padding: 8px 0 !important;
            }

            .td07 {
                width: 138px;
            }
        }
    }
}

.featurebox {
    @extend .flex-start;
    @include flex-wrap(wrap);

    .list {
        position: relative;
        background: #f6f6f6;
        @extend .flex-start;
        @include flex-wrap(nowrap);
        margin: 20px 10px 0 0;
        padding: 8px 20px 8px 10px;
        border-radius: 20px;
    }

    .del {
        color: #ff392b;
        font-size: 16px;
        position: absolute;
        top: -8px;
        right: 0;
        cursor: pointer;
    }
}
