@import "../../assets/styles/flex";
@import "../../assets/styles/mixins";
.upload-imagebox { border: 1px solid #CCCCCC; padding: 20px;
    .ant-upload {
        &-picture-card-wrapper { border: 0 none; padding: 0; width: 154px; }
        &-list { display: none; }
        &-select-picture-card.ant-upload { width: 146px; height: 146px; }
    }
    .picture-list { width: 146px; height: 146px; border: 1px solid #d9d9d9; border-radius: 2px; margin: 0 8px 8px 0; padding: 8px; display: inline-block; vertical-align: top;
        &:hover {
            .picture-list-actions {
                @include flexbox
            }
        }
        &-inner {
            width: 100%;
            height: 100%;
            @extend .flex-center;
            @include flex-direction(column);
            position: relative;
        }
        &-img { display: block; width: 100%; max-height: 100%;
            &-signbox { position: absolute; top: 0; left: 0;
                &_item { background: #ff4d4f; color: #fff; font-size: 12px; padding: 1px 5px; margin-bottom: 5px; }
            }
        }
        .loading { width: 24px; margin-top: 10px; }
        &-error { border-color: #ff4d4f; color: #ff4d4f; }
        &-actions {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            @include bg-rgba(#000,50);
            display: none;
            @extend .flex-center;
            &-icon { cursor: pointer; color: #fff; margin: 0 5px; font-size: 15px; }
        }
    }
}
