﻿//锯齿
@mixin sawtooth($direction,$color,$bgColor) {
    width: 16px;
    background-size: 40px 16px;

    @if $direction== left {
        background-color: $color;
        background-position: 100% 35%;
        background-image: linear-gradient(-45deg, $bgColor 25%, transparent 25%, transparent), linear-gradient(-135deg, $bgColor 25%, transparent 25%, transparent), linear-gradient(-45deg, transparent 75%, $bgColor 75%), linear-gradient(-135deg, transparent 75%, $bgColor 75%);
    }

    @if $direction== right {
        background-color: $bgColor;
        background-position: 100% 15%;
        background-image: linear-gradient(-45deg, $color 25%, transparent 25%, transparent), linear-gradient(-135deg, $color 25%, transparent 25%, transparent), linear-gradient(-45deg, transparent 75%, $color 75%), linear-gradient(-135deg, transparent 75%, $color 75%);
    }
}

//1、浏览器前缀
@mixin css3($property, $value) {
    @each $prefix in -webkit-, -moz-, -ms-, -o-, '' {
        #{$prefix}#{$property}: $value;
    }
}
//2、绝对定位
@mixin abs-pos ($top: auto, $right: auto, $bottom: auto, $left: auto) {
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
    position: absolute;
}

//3、圆角
@mixin border-radius($radius: 3px) {
    border-radius: $radius;
}

%border-radius {
    @include border-radius;
}
//4、灰边
%border {
    border: 1px solid #dcdcdc;
}


//4、盒模型阴影box-shadow
//rgba(0,0,0,.5) 颜色透明度
@mixin shadow($x, $y, $blur, $color) {
    box-shadow: $x $y $blur $color;
}
//----------------------------------------
// 5. Opacity
//----------------------------------------
@mixin opacity($opacity: 50) {
    opacity: $opacity / 100;
    filter: alpha(opacity=$opacity);
}

@mixin bg-rgba($color: #fff,$opacity: 80) {
    background-color: $color;
    background-color: rgba($color,$opacity / 100);
    filter: Alpha(opacity=$opacity);
}

//6、 渐变 CSS3 gradients
@mixin linear-gradient($from, $to) { /* Fallback for sad browsers */
    background-color: $to; /* Mozilla Firefox */
    background-image: -moz-linear-gradient($from, $to); /* Opera */
    background-image: -o-linear-gradient($from, $to); /* WebKit (Safari 4+, Chrome 1+) */
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, $from), color-stop(1, $to)); /* WebKit (Chrome 11+) */
    background-image: -webkit-linear-gradient($from, $to); /* IE10 */
    background-image: -ms-linear-gradient($from, $to); /* W3C */
    background-image: linear-gradient($from, $to);
}

//7、box-sizing
@mixin box-sizing {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
//-------------------------------------
// 7. text-overflow
// 7.1 单行截取文本
// 7.2 多行截取文本

@mixin text-overflow {
    overflow: hidden;
    -o-text-overflow: ellipsis;
    -ms-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/**
 * 实现多行文本省略，
 */
@mixin lamp-clamp($line) { 
    overflow: hidden; 
    text-overflow: ellipsis; 
    display: -webkit-box; 
    -webkit-line-clamp: $line; 
    -webkit-box-orient: vertical; 
}

//--------------------------------------
//1.单行文本截取
//`$substract`单位为%
@mixin singleline-ellipsis($substract: 0) {
    overflow: hidden;
    -o-text-overflow: ellipsis;
    -ms-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100% - $substract;
}

%singleline-ellipsis {
    @include singleline-ellipsis;
}

//----------------------------------------
// 8. trangile
//----------------------------------------
//1. $size定义三角大小
//2. $color定义三角颜色
//3. $bgColor定义内嵌三角颜色
//4. $direction定义三角方向
//5. //其父元素需要定义为position:realtive
%triangle-basic {
    content: "";
    height: 0;
    width: 0;
    overflow: hidden;
    position: absolute;
}

@mixin triangle($direction, $size, $borderColor ) {
    @extend %triangle-basic;

    @if $direction == up {
        border-bottom: $size solid $borderColor;
        border-left: $size dashed transparent;
        border-right: $size dashed transparent;
    }
    @else if $direction == right {
        border-left: $size solid $borderColor;
        border-top: $size dashed transparent;
        border-bottom: $size dashed transparent;
    }
    @else if $direction == down {
        border-top: $size solid $borderColor;
        border-left: $size dashed transparent;
        border-right: $size dashed transparent;
    }
    @else if $direction == left {
        border-right: $size solid $borderColor;
        border-top: $size dashed transparent;
        border-bottom: $size dashed transparent;
    }
}


//----------------------------------------
// 9. 色相  hsb 转 hsl
//----------------------------------------
//1. $h 色相颜色
//2. $hs 定义hsb S饱和度百分比  可以负数  格式：-30%
//3. $hb 定义hsb B明度百分比  可以负数 格式：30%
//4. $vv 赋予参数，hsl为当前色值；$vv 为空，通过饱和度，明度改变颜色的色相值
@function hsb-color($h,$hs: 0,$hb: 0,$vv: null) {
    $ss: 0;
    $ll: 0;

    @if type_of($h) == 'color' {
        $ll: lightness($h);
        $ss: saturation($h);
        $h: hue($h);
    }

    @if unit($h) == 'deg' {
        $h: 3.1415 * 2 * ($h / 360deg);
    }

    @if unit($ss) == '%' {
        $ss: 0 + ($ss / 100%);
    }

    @if unit($ll) == '%' {

        $ll: 0 + (if($ll==0%,0.001%,$ll) / 100%);

    }

    $ll: $ll * 2;

    @if $ll <= 1 {
        $ss: $ss * $ll;
    }
    @else {
        $ss: $ss * (2 - $ll);
    }

    $v: ($ll + $ss) / 2;
    $s: (2 * $ss) / ($ll + $ss);


    $h-hsb: 360deg * $h / (3.1415 * 2);
    $s-hsb: percentage(max(0, min(1, $s)));
    $b-hsb: percentage(max(0, min(1, $v)));

    @if $vv ==null {
        @if ($s-hsb + $hs) >= 100% {
            $s-hsb: percentage(max(0, min(1, $s)))
        }
        @else if ($s-hsb + $hs) <= 0% {
            $s-hsb: percentage(max(0, min(1, $s)))
        }
        @else {
            $s-hsb: percentage(max(0, min(1, $s))) + $hs;
        }

        @if ($b-hsb + $hb) >= 100% {
            $b-hsb: percentage(max(0, min(1, $v)));
        }

        @else if($b-hsb + $hb) <= 0% {
            $b-hsb: percentage(max(0, min(1, $v))) + 0.1%;
        }
        @else {
            $b-hsb: percentage(max(0, min(1, $v))) + $hb;
        }
    }
    @else {
        @if unit($hs) == '%' {
            $s-hsb: if($hs>=0%,$hs,percentage(max(0, min(1, $s))));
        }
        @else {
            $s-hsb: percentage(max(0, min(1, $s)));

        }

        @if unit($hb) == '%' {
            $b-hsb: if($hb>=0%,$hb,percentage(max(0, min(1, $v))));
        }
        @else {
            $b-hsb: percentage(max(0, min(1, $v)));

        }
    }

    $l-hsl: ($b-hsb/2) * (2 - ($s-hsb/100));
    $s-hsl: ($b-hsb * $s-hsb) / if($l-hsl < 50, $l-hsl * 2, 200 - $l-hsl * 2);
    @return hsl($h-hsb, $s-hsl, $l-hsl);
}

@mixin transform($t) {
    -webkit-transform: $t;
    transform: $t;
}

@mixin translate3d($x: 0, $y: 0, $z: 0) {
    @include transform(translate3d($x,$y,$z));
}

@mixin translateX($x: 0) {
    @include transform(translateX($x));
}

@mixin translateY($y: 0) {
    @include transform(translateY($y));
}

@mixin position-center {
    position: absolute;
    top: 50%;
    left: 50%;
    @include translate3d(-50%,-50%,0);
}
