@import ".../../../../assets/styles/mixins.scss";
    .time-modal {
        top: 5%;
    }

.news-pic-modal, .news-pape {
    .newsGKshuiyin {
        width: 50%;
        height: 110px;
        position: absolute;
        top: 50%;
        left: 50%;
        @include translateX(-50%);
        @include translateY(-50%);
    }

    display: block;
    position: absolute;
    top: 6%;
    left: 50%;
    width: 320px;
    @include translateX(-50%);
    z-index: 9999;
    margin-left: 0px;


    .news-pd10 {
        padding: 20px;
        background: #fff;
        margin: 10px;
        margin-top: 10px;
        margin-left: 15px;
        margin-right: 15px;
        min-height: 390px;
        border: 1px solid #565c95;
        border-radius: 15px;
    }

    
    .news-times {
        text-align: right;
        font-size: .9rem;
        color: #2e357d;
        margin-top: 4px;
    }

    .news-paper {
        background: url("../../assets/image/jianbao/lvxunPic1.png");
        background-size: 100% 100%;
        min-height: 366px;
        max-height: 5000px;
        position: relative;
        padding-top: 10px;
    }

    .travel-message {
        color: #313785;
        font-weight: bold;
        font-size: 20px;
    }

    .today-language {
        color: #02418b;
        font-size: 18px;
    }

    .jiyu-mt10 {
        margin-top: 10px;
    }

    .golbal-service {
        font-size: 14px;
        color: #2e357d;
        margin-left: 10px;
        font-weight: 560;
        margin-top: 4px;
    }

    .code-message {
        color: #2e357d;
        padding-left: 10px;
        font-size: 12px;
        margin-top: 8px;
        font-weight: bold;
    }

    .code-pd10 {
        padding: 15px;
        padding-bottom: 50px;
    }

    .time-l {
        color: #2e357d;
        margin-right: 2px;
    }

    .pc-text {
        font-size: 18px;
        margin-left: 4px;
        color: #02418b;
    }

    .golbal-service {
        font-size: 12px;
    }
}

@media (min-width: 400px) {
    
    .news-pic-modal .golbal-service {
        font-size: 16px;
    }

    .news-pic-modal .code-message, .news-pape .code-message {
        font-size: 13px;
    }
}

@media (max-width: 400px) {

    .search-chaxun {
        margin-top:10px;
    }
}

