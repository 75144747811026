﻿@import "mixins.scss";
@import "theme.scss";
@import "flex.scss";
.col25 { width:25%;}
.col50 { width:50%;}
.col100 { width:100%}
.red { color:red;}

.label { line-height: 32px; }

.mt8 { margin-top:8px;}
.mb8 { margin-bottom: 8px; }

.mt5 { margin-top:5px;}
.mr5 { margin-right: 5px; }
.mb5 { margin-bottom: 5px; }
.ml5 { margin-left: 5px; }

.mt10 { margin-top:10px;}
.ml10 { margin-left: 10px; }
.mr10 {margin-right:10px; }

.mb15 { margin-bottom:15px;}

.mt20 { margin-top: 20px; }
.mr20 { margin-right:20px;}
.ml20 { margin-left:20px;}

.fb { font-weight:bold;}

.ft14 { font-size:14px;}
.ft16 { font-size:16px;}
.ft18 { font-size:18px;}

.gray5 { color:#555;}
.gray6 { color:#666;}
.gray9 { color:#999;}

.text-overflow {
    @include text-overflow
}

.btn { padding: 4px 6px; border-radius: 4px; cursor: pointer; flex-shrink: 0; border: 1px solid transparent;height:auto;
    & + .btn {margin-left:10px; }
}
.btn-primary { color: #fff; background-color: #428bca; border-color: #357ebd; }
.btn-danger { color: #fff; background-color: #d9534f; border-color: #d43f3a; }
.btn-default { background: #ecf6ff;border-color:#ccc; }
.btn-customize { background: #fafafa; border-color: #e8e8e8; }
.btn-light { background: #fff; color: #428bca; border-color: #428bca; }
.btn-sub { color: #fff; background-color: $palette-sub; border-color: $palette-sub; }

.ant-popover {z-index:1055; }