﻿$yqf-red: #f44848;
$palette-main: #D62027;
//$palette-sub: #E75756;

$palette-light: #ffa50b;

$palette-sub: #6777b4 !default;
$palette-sub-light: lighten($palette-sub,10%) !default;

$icon-color: #d2d2d2;
$input-bg-color: #f5f6f9;
$background-color: #f5f5f5;
$fill-body: #f5f5f5;

$disabled-color: #dddddd;

$black: #000000;
$white: #ffffff;

$text-color: #333333;
$text-color-light: #666666;
$text-color-lighter: #999999;
$text-color-lightest: #cccccc;

$border-color: #ebebeb;
$border-radius: 4px;

$image-bg: #f4f4f4;

$price-color: $yqf-red;

$correct-color: #45da84; //打钩颜色，通过颜色
$error-color: #fa5e5b; //打叉颜色，拒绝颜色
