@import "assets/styles/reset";

html, body {
}

.manager-box {
    .copy-example {
        cursor: copy;
        color: #1890ff;
    }

    .news-paper-manager {
        padding: 20px;
    }

    .mt10 {
        margin-top: 10px;
    }

    .icon-managerMent {
        position: absolute;
        left: 0px;
        top: 6px;
    }

    .news-manager {
        text-align: center;
    }

    .news-manager {
        height: 40px;
        line-height: 40px;
        background: #f44848;
        color: #fff;
    }

    .add-paper {
        padding: 10px;
    }
}

.kuaixun-global {
    width: 90%;
    border: 1px solid #ececec;
    padding: 10px;
    border-radius: 6px;
    margin-top: 15px;
    margin-bottom: 15px;
    height: 248px;
    overflow-y: scroll;



    .gray-time {
        color: #999;
        font-size: 12px;
        margin-bottom: 20px;
    }

    .icon-top {
        color: #1890ff;
        margin-top: 5px;
    }
}

.time-modal {
    .global-kuaixun {
        padding-bottom: 20px;
        border-bottom: 1px solid #ececec;
        margin-bottom: 20px;
    }

    .num-box {
        margin-top: 10px;
    }

    .gray-time {
        color: #999;
        font-size: 12px;
    }

    .icon-top {
        color: #1890ff;
    }

    .icon-top2 {
        margin-top: 10px;
    }
}

.news-pic-modal, .news-pape {
    .line-over {
        word-break: break-all;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
    }
    /* .ziyuan{
       font-size:13px;
       font-weight:bold;
    }*/
    .pc-text {
        font-size: 18px;
        margin-left: 4px;
    }

    .xinghao {
        color: #313785;
        font-weight: bold;
        margin-right: 10px;
        font-size: 20px;
        display: inline-block;
        vertical-align: top;
    }

    .xing-tag {
        width: 8px;
        height: 8px;
        margin-right: 10px;
        margin-left: 4px;
        display: inline-block;
        margin-top: 5px;
    }



    #export-img {
        img {
            width: 100%;
            height: 100%;
        }
    }

    display: block;
    position: absolute;
    top: 18px;
    left: 50%;
    width: 320px;
    margin-left: -160px;
    z-index: 9999;

    .news-title {
        font-size: 16px;
        color: #fff;
        font-weight: bold;
    }

    .news-p {
        padding: 20px;
        padding-bottom: 6px;
    }

    .news-pd10 {
        padding: 10px;
        background: #fff;
        border-radius: 2px;
        margin: 35px;
        position: relative;
        margin-left: 15px;
        margin-right: 15px;
        min-height: 390px;
    }

    .news-times {
        text-align: right;
        font-size: .9rem;
        color: #fff;
        margin-top: 4px;
    }

    .news-start {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .news-paper.news-paper2 {
        background: url("./assets/image/jianbao/bg-gnk2.png");
        background-size: 100% 100%;
        min-height: 366px;
        max-height: 5000px;
        position: relative;
        padding-top: 10px;
    }

    .biaoqian-img {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: -10px;
    }

    .travel-message.travel-message2 {
        color: #313785;
        font-weight: bold;
        font-size: 20px;
        display: inline-block;
        background: url("./assets/image/jianbao/biaoqian.png");
        /* padding: 15px 30px; */
        background-size: 100% 100%;
        width: 88px;
        height: 52px;
        text-align: center;
        line-height: 40px;
        color: #fff;
    }

    .today-language {
        color: #454545;
        font-size: 18px;
    }

    .jiyu-mt10 {
        margin-top: 10px;
    }

    .jiyu-bg-text {
        color: #313785;
        font-weight: bold;
        font-size: 20px;
        display: inline-block;
        background: url("./assets/image/jianbao/jiyu-bg.png");
        /* padding: 15px 30px; */
        background-size: 100% 100%;
        width: 145px;
        height: 39px;
        text-align: center;
        line-height: 32px;
        color: #fff;
    }

    .golbal-service {
        font-size: 14px;
        color: #fff;
        margin-left: 10px;
        font-weight: 560;
    }

    .code-message {
        color: #fff;
        padding-left: 10px;
        font-size: 12px;
        margin-top: 8px;
        font-weight: bold;
    }

    .code-pd10 {
        padding: 15px;
        padding-bottom: 50px;
    }

    .time-l {
        color: #fff;
        margin-right: 2px;
    }
}

.news-pape {
    position: initial;
    margin-left: 0px;
    width: 100%;
}

.show-modal {
    position: absolute;
    top: 0px;
    background: #fff;
    z-index: 999;
    height: 100%;
}

.none-modal {
    display: none;
}

.login-wrap {
    overflow: auto;
    height: 100vh;
    background-repeat: no-repeat;
    background-position: center 110px;
    background-size: 100%;
    background-color: #f0f2f5;

    .inner {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        padding: 20px;
    }

    .wrap {
        display: table;
        width: 400px;
        margin: 0 auto;
        min-height: 100%
    }

    .logo {
        width: 72px;
        height: 72px;
        display: block;
        margin: 0 auto;
    }
}

.loginbox {
    background: #fff;
    padding: 40px;
    margin: 65px 0 85px;

    .title {
        font-size: 24px;
        margin-bottom: 30px;
    }

    .ant-form {
        text-align: left;

        .ant-alert {
            margin-bottom: 20px;
        }

        .ant-input-affix-wrapper {
            max-height: none
        }

        .loginbtn {
            width: 100%;
            height: auto;
            padding: 10px;
            font-size: 16px;
        }
    }
}

.ant-layout {
    overflow: hidden;

    &-header {
        position: fixed;
        width: calc(100% - 200px);
        z-index: 2;
        padding: 0px;
        display: flex;
        flex-direction: row;
        align-items: center;
        box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

        .ant-breadcrumb {
            white-space: nowrap;
            margin-left: 24px;
        }

        .profile {
            .ant-dropdown-trigger {
                display: flex;
                align-items: center;
                height: 100%;
                padding-right: 24px;
                cursor: pointer;
                white-space: nowrap;
            }

            .ant-avatar {
                margin-right: 5px;
            }
        }
    }

    &-content {
        min-height: 100vh;
        padding-top: 64px;
    }

    &-footer {
        margin-top: -70px;
        text-align: center;
    }

    &-sider-collapsed {
        .logo {
            padding-left: 8px;
        }
    }

    &-sider {
        z-index: 1;
        position: fixed;
        height: 100vh;

        .logo {
            height: 32px;
            margin: 16px;
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            overflow: hidden;
            transition: all 0.3s;

            img {
                display: inline-block;
                height: 32px;
                vertical-align: middle;
            }

            h1 {
                display: inline-block;
                margin-left: 4px;
                color: rgba(255, 255, 255, 0.9);
                font-size: 16px;
                font-weight: bold;
                animation: face-in;
                animation-duration: 0.3s;
            }
        }

        .nav_menu {
            position: absolute;
            left: 0;
            right: 0;
            top: 64px;
            bottom: 0;
        }
    }
}

.site-layout {
    margin-left: 200px;

    &-full {
        margin-left: 0 !important;
    }

    &-background {
        background-color: #fff;
    }

    &-footfull {
        /*height: 70px;*/
    }

    &-body {
        padding: 24px;

        .site-layout-background {
            min-height: calc(100vh - 114px);
        }

        .workspace-fixed {
            height: calc(100vh - 114px);
        }
    }

    .header-full {
        width: 100%;
    }
}

.components-layout-trigger {
    .trigger {
        font-size: 18px; /*line-height: 64px;*/
        padding: 0 24px;
        cursor: pointer;
        transition: color 0.3s;

        &:hover {
            color: #1890ff;
        }
    }
}

.ant-table {
    .ant-btn-link {
        padding: 0;
        height: unset;
    }

    .ant-table-content {
        overflow-x: auto !important;
    }

    &.ant-table-bordered .ant-table-title {
        border: none;
    }

    .editable-cell-value-wrap {
        padding: 5px 12px;
        cursor: pointer;
    }

    .editable-row .editable-cell-value-wrap:hover {
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        padding: 4px 11px;
    }

    [data-theme='dark'] .editable-row .editable-cell-value-wrap:hover {
        border: 1px solid #434343;
    }

    .ant-table-pagination-right {
        margin-right: 8px !important;
    }

    tr.drop-over-downward td {
        border-bottom: 1px dashed #1890ff !important;
        opacity: 0.6;
    }

    tr.drop-over-upward td {
        border-top: 1px dashed #1890ff !important;
        opacity: 0.6;
    }
}

.ant-breadcrumb-link {
    a {
        color: #1890ff;
    }
}

.filter-box {
    padding: 16px 24px;

    .ant-descriptions {
        margin-bottom: 12px;
    }

    .ant-descriptions-title {
        margin-bottom: 10px;
        font-size: 14px;
    }

    .ant-descriptions-view > table {
        table-layout: fixed;
    }

    .ant-descriptions-item-label {
        white-space: nowrap;
    }

    .ant-descriptions-item-block {
        @extend .flex-between;
    }

    &-title-90 {
        th {
            width: 1px;
        }

        .ant-descriptions-item-label {
            width: 90px;
        }
    }

    &-title-100 {
        th {
            width: 1px;
        }

        .ant-descriptions-item-label {
            width: 100px;
        }
    }

    &-title-110 {
        th {
            width: 1px;
        }

        .ant-descriptions-item-label {
            width: 110px;
        }
    }

    &-title-170 {
        th {
            width: 1px;
        }

        .ant-descriptions-item-label {
            width: 170px;
        }
    }
}


.ant-table-autofit {
    height: 100%;

    .ant-spin-nested-loading, .ant-spin-container, .ant-table, .ant-table-container, .ant-table-content {
        height: 100%;
    }

    .ant-spin-container {
        display: flex;
        overflow: hidden;
        flex-direction: column;

        .ant-table {
            border: 1px solid #f0f0f0;
            flex-grow: 1;
            overflow: hidden;
        }

        .ant-table-pagination-right {
            align-self: flex-end;
        }
    }

    .ant-table-container {
        /*display: flex;
        flex-direction: column;*/
    }

    .ant-table-header {
        /*overflow: unset !important;*/
    }

    .ant-table-body {
        flex-grow: 1;
        /*overflow-y: auto !important;*/
        overflow-x: auto !important;
        height: calc(100% - 38px);

        tr:last-child {
            td {
                border-bottom-style: none;
            }
        }
    }

    .ant-table-tbody {
        .ant-table-placeholder td {
            border-bottom-style: none;
        }
    }
}

.ant-tabs .ant-tabs-left-bar .ant-tabs-tab {
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
}

.ant-modal {
    &-body {
        max-height: 450px;
        overflow-y: auto;
    }
}

.flex-start {
    display: flex;
}

.align-center {
    display: flex;
    align-items: center;
}

.ml8 {
    margin-left: 8px;
}

.mr8 {
    margin-right: 8px;
}

.mb10 {
    margin-bottom: 10px;
}

.mb20 {
    margin-bottom: 20px;
}

.strong {
    font-weight: bold;
    color: #1890ff;
    font-size: 15px;
}


.product-mange {
    .ant-form {
        color: #333;

        &-item {
            margin-bottom: 15px;
        }

        &-item-label {
            min-width: 110px;
        }

        .ant {
            &-input, &-select, &-input-number {
                width: 30%;
                min-width: 150px;
            }

            &-picker {
                width: 40%;
                min-width: 200px;
            }

            &-textarea {
                width: 50%;
                resize: none;
                height: 120px;
            }
        }
    }

    &-item {
        &-required {
            .ant-form-item-label label {
                &:before {
                    display: inline-block;
                    margin-right: 4px;
                    color: #ff4d4f;
                    font-size: 14px;
                    font-family: SimSun, sans-serif;
                    line-height: 1;
                    content: '*';
                }
            }
        }
    }

    &-block {
        font-size: 14px;
        padding: 20px;

        & + & {
            border-top: 1px solid #dcdcdc;
            padding-top: 20px;
            margin-top: 10px;
        }

        h4 {
            font-weight: bold;
            font-size: 18px;
            margin: 0;
        }
    }

    &-header {
        @extend .flex-between;
        margin-bottom: 20px;

        &-tit {
            font-weight: bold;
            font-size: 18px;
        }

        .effect {
            color: #5cb85c;
            font-weight: bold;
        }

        .ineffect {
            color: #d43f3a;
            font-weight: bold;
        }
    }

    .ant-row {
        .ant-col-24 {
            .ant-form-item {
                width: 100%;
                padding-right: 20px;
            }
        }

        .ant-form-item {
            width: 100%;
            padding-right: 20px;

            .ant-input, .ant-select, .ant-input-number, .ant-picker {
                width: 100%;
                min-width: auto;
            }
        }
    }

    .nodot {
        .ant-form-item-label > label::after {
            display: none;
        }

        .ant-checkbox-disabled + span {
            color: inherit;
        }
    }

    &-btnbox {
        @extend .flex-center;
        padding: 20px 0;

        .btn {
            padding: 10px 50px;
            font-size: 16px;
            margin: 0 20px;
        }
    }

    &-fileUpload {
        .uploadButton {
            border: 1px solid #dcdcdc;
            padding: 2px 20px;
            cursor: pointer;
            @extend .flex-start;
            @include align-items(center);

            &-name {
                margin-left: 5px;
            }
        }
    }
}

.product-modal {
    width: 80%;
    max-width: 1100px;

    .ant-form {
        .ant {
            &-input, &-select, &-input-number, &-picker {
                width: 100%;
                min-width: auto;
            }

            &-textarea {
                width: 100%;
                resize: none;
                height: 120px;
            }
        }

        textarea {
            width: 100%;
            resize: none;
            height: 120px;
        }
    }
}

input[type=number] {
    -moz-appearance: textfield;

    &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        -webkit-appearance: none;
        margin: 0;
    }
}
.product-choose {
    position: absolute;
    width: 326px;
    background: #fff;
    border: 1px solid #ececec;
   /* border-bottom: 0px;*/
    z-index: 999;
}
.product-choose-item{
    padding:10px;
    /*border-bottom:1px solid #ececec;*/
}
@media (max-width: 768px) {
    /* .pc-text{
        font-size:17px;
    }*/
}

@media (max-width: 768px) {
    .news-pic-modal .news-times {
        font-size: 10px;
    }

    #node {
        width: 100% !important;
    }

    .news-pape .golbal-service {
        font-size: 12px;
    }

    .news-pic-modal .golbal-service {
        font-size: 12px;
    }
}

@media (min-width: 400px) {
    .news-pic-modal .golbal-service {
        font-size: 14px;
    }
}

@media (max-width: 400px) {
    .news-pic-modal .golbal-service {
        font-size: 12px;
    }
}

.news-paper2 {
    &.ant-modal {
        top: 40%;
    }
}

.toppic-modal {
    &.ant-modal {
        top: 20%;
    }
}

.haibao {
    &-box {
        background: url("./assets/image/example/haibao-bg.jpg");
        background-size: 100% 100%;
        height: 540px;
        width: 300px;
        margin: 0 auto;
        position: relative;
        overflow: hidden;
    }

    &-img {
        /* height: 290px !important;*/
        border-radius: 10px;
    }

    &-img-b {
        margin-top: 20px;
    }

    &-erweima {
        padding: 6px;
        background: url("./assets/image/example/erweima-bg.png");
        background-size: 100% 100%;
    }

    &-qiye {
        font-size: 15px;
        font-weight: bold;
        color: #fff;
    }

    &-b {
        position: absolute;
        width: 100%;
        bottom: 58px;
    }

    &-img-haibao {
        background: url("./assets/image/example/white-poster.png");
        height: 130px;
        width: 88%;
        margin-top: 92px;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

