﻿@mixin flexbox {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

@mixin flex-wrap($fw) {
    -webkit-flex-wrap: $fw;
    -ms-flex-wrap: $fw;
    flex-wrap: $fw;

    @if $fw==nowrap {
        -webkit-box-lines: single;
        -moz-box-lines: single;
        -ms-flex-wrap: none;
    }
    @else if $fw==wrap {
        -webkit-box-lines: multiple;
        -moz-box-lines: multiple;
    }
}


@mixin flex-shrink($fs) {
    -webkit-flex-shrink: $fs;
    -ms-flex: 0 $fs auto;
    flex-shrink: $fs;
}

@mixin flex($fg: 1, $fs: null, $fb: null) {
    -webkit-box-flex: $fg;
    -webkit-flex: $fg $fs $fb;
    -moz-box-flex: $fg;
    -moz-flex: $fg $fs $fb;
    -ms-flex: $fg $fs $fb;
    flex: $fg $fs $fb;
}

@mixin flex-direction($fd) {
    @if $fd==row {
        -webkit-box-orient: horizontal;
        -moz-box-orient: horizontal;
        -webkit-box-direction: normal;
        -moz-box-direction: normal;
    }

    @if $fd==column {
        -webkit-box-orient: vertical;
        -moz-box-orient: vertical;
        -webkit-box-direction: normal;
        -moz-box-direction: normal;
    }

    @if $fd==row-reverse {
        -webkit-box-orient: horizontal;
        -moz-box-orient: horizontal;
        -webkit-box-direction: reverse;
        -moz-box-direction: reverse;
    }
    @else {
        -webkit-box-orient: vertical;
        -moz-box-orient: vertical;
        -webkit-box-direction: reverse;
        -moz-box-direction: reverse;
    }

    -webkit-flex-direction: $fd;
    flex-direction: $fd;
}

@mixin justify-content($jc) {
    @if $jc==flex-start {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        -webkit-justify-content: flex-start;
        justify-content: flex-start;
    }

    @else if $jc==flex-end {
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        -webkit-justify-content: flex-end;
        justify-content: flex-end;
    }

    @else if $jc==space-between {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        -webkit-justify-content: space-between;
        justify-content: space-between;
    }
    @else if $jc==space-between {
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        -webkit-justify-content: flex-end;
        justify-content: flex-end;
    }
    @else {
        -webkit-box-pack: $jc;
        -ms-flex-pack: $jc;
        -webkit-justify-content: $jc;
        justify-content: $jc;
    }
}

@mixin align-items($ai) {
    @if $ai==flex-start {
        -webkit-box-align: start;
        -ms-flex-align: start;
        -webkit-align-items: flex-start;
        align-items: flex-start;
    }
    @else if $ai==flex-end {
        -webkit-box-align: end;
        -ms-flex-align: end;
        -webkit-align-items: flex-end;
        align-items: flex-end;
    }
    @else {
        -webkit-box-align: $ai;
        -ms-flex-align: $ai;
        -webkit-align-items: $ai;
        align-items: $ai;
    }
}

@mixin align-content($ai) {
    -ms-flex-line-pack: $ai;
    -webkit-align-content: $ai;
    align-content: $ai;
}

@mixin align-self($as) {
    -ms-flex-item-align: $as;
    -webkit-align-self: $as;
    align-self: $as;
}


.flex-start {
    @include flexbox; 
    @include justify-content(flex-start);
}

.flex-end {
    @include flexbox;
    @include justify-content(flex-end);
}

.flex-between {
    @include flexbox;
    @include justify-content(space-between);
}

.flex-center {
    @include flexbox;
    @include justify-content(center);
}

.flex-start, .flex-between, .flex-around, .flex-center, .flex-end {
    @include align-items(center)
}

.align-baseline { display: flex; align-items: baseline; }

.flex-wrap { flex-wrap: wrap; }
.flex-shrink { flex-shrink: 0; }
